import React from "react";
import styled from "styled-components";
import RawTextInput from "../../components/text-input/text-input.component";
import { ReactComponent as logo } from "../../assets/logo.svg";

export const FormWrapper = styled.div`
  display: flex;
  background: white;
  width: 90%;
  max-width: 500px;
  flex-direction: column;
  border-radius: 20px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(logo)`
  width: 150px;
  padding-bottom: 20px;
`;

export const Form = styled.form`
  padding: 10px;
  width: 90%;
`;

export const Body = styled.main`
  background: #39a7ff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Text = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 12)}px;
  color: ${({ color }) => (color ? color : "#000000")};
  text-align: center;
`;

export const TextInput = styled(RawTextInput).attrs({
  labelStyle: { color: "#2296F3", fontSize: 16 },
  wrapperStyle: { marginBottom: 21, width: "100%" },
})``;

const CheckboxBtn = styled.button.attrs({
  type: "button",
})`
  background: #2296f3;
  border-color: #2296f3;
  border-style: solid;
  border-width: 0;
  padding: 7px;
  outline: none;
`;

export const Checkbox = ({
  value,
  onChange = () => {},
  renderLabel = () => null,
}) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <CheckboxBtn
      style={
        value ? {} : { borderWidth: 1, padding: 6, background: "transparent" }
      }
      onClick={() => onChange(value ? 0 : 1)}
    />
    <div style={{ marginLeft: 5 }}>{renderLabel()}</div>
  </div>
);

export const ErrorText = styled.p`
  margin: 5px 0;
  font-size: 11px;
  text-align: center;
  height: 10px;
  color: red;
`;
