import React from "react";
import styled from "styled-components";

export default React.forwardRef(({ 
  label,
  wrapperStyle,
  labelStyle,
  onChange= () => {},
  error,
  style,
  ...props
}, ref) => (
  <div style={wrapperStyle}>
    {label && <Label style={labelStyle}>{label}</Label>}
    <TextInput 
      ref={ref} 
      onChange={({ target: { value } }) => onChange(value)}
      style={{ ...style, ...(error ? { backgroundColor: '#E5CACA', borderColor: '#B40000' } : {}) }}
      {...props} 
    />
    {error && <ErrorMessage>{error.type === 'required' ? 'Campo obbligatorio' : error.message}</ErrorMessage>}
  </div>
));

const Label = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

const TextInput = styled.input.attrs((p) => ({
  type: p.type || "text",
}))`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  height: 40px;
  font-size: 12px;
  border: solid #808080 1px;
  border-radius: 5px;
  color: #000000;

  &::placeholder {
    color: #808080;
  }

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ErrorMessage = styled.p`
  color: #b40000;
  margin: 6px 0 0 12px;
`;
