import React, { useState, useEffect, useContext } from "react";
import Api from "../../services/api.service";
import NavBar from "../../layout/nav-bar/nav-bar";
import AppContext from "../../services/context.service";
import {
  ProfilePageWrapper,
  UserBar,
  Wrapper,
  ItemRow,
  ItemWrapper,
  CoinsBar,
  PlusButton,
  EnergyBar,
  NotesBar,
  UserName,
  Row,
  LevelWrapper,
  HexagonWrapper,
  Hexagon,
  UserLevel,
  ProfilePic,
  XpBar,
  XpLevel,
  Xp,
  CountsRow,
  GamesCount,
  PlaylistsCount,
  CountText,
  NavigationRow,
  NavLink,
  Logout,
} from "./profile.style";
import Loading from "../../layout/loading-screen/loading";
import { ReactComponent as Coins } from "../../assets/coins.svg";
import { ReactComponent as Energy } from "../../assets/energy.svg";
import { ReactComponent as Notes } from "../../assets/notes.svg";

const Profile = (props) => {
  const { logout } = useContext(AppContext);
  const [userData, setUserData] = useState();
  useEffect(() => {
    async function getUser() {
      const response = await Api.user();
      setUserData(response.data);
    }
    getUser();
  }, []);
  return userData ? (
    <ProfilePageWrapper>
      <UserBar>
        <Wrapper>
          <Row noMargin>
            <UserName>{userData.name}</UserName>
            <Logout
              onClick={() => logout()}
              style={{ border: "2px solid white" }}
            >
              Logout
            </Logout>
          </Row>
          <ItemRow>
            <ItemWrapper>
              <Coins />
              <CoinsBar> {userData.wallet.coins} </CoinsBar>
              <PlusButton />
            </ItemWrapper>
            <ItemWrapper>
              <Energy />
              <EnergyBar> {userData.wallet.energy_points} </EnergyBar>
              <PlusButton />
            </ItemWrapper>
            <ItemWrapper>
              <Notes />
              <NotesBar> {userData.wallet.musical_notes} </NotesBar>
              <PlusButton />
            </ItemWrapper>
          </ItemRow>
          <Row>
            <LevelWrapper>
              <HexagonWrapper>
                <Hexagon />
                <UserLevel>{userData.level}</UserLevel>
              </HexagonWrapper>
              <XpBar>
                <XpLevel level={userData.level_percentage} />
              </XpBar>
              <Xp>{Math.trunc(userData.level_percentage)}%</Xp>
            </LevelWrapper>
            <ProfilePic />
          </Row>{" "}
          <CountsRow>
            <GamesCount>
              {userData.user_games_count}
              <CountText>Partite giocate</CountText>
            </GamesCount>
            <PlaylistsCount>
              {userData.user_games_playlists_count}
              <CountText>Playlist giocate</CountText>
            </PlaylistsCount>
          </CountsRow>
        </Wrapper>
      </UserBar>
      <NavBar />
      <NavigationRow>
        <NavLink>Partite</NavLink>
        <NavLink>badge</NavLink>
      </NavigationRow>
    </ProfilePageWrapper>
  ) : (
    <Loading />
  );
};

export default Profile;
