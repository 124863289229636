import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ReactComponent as player } from "../../assets/player_icon.svg";
import { ReactComponent as playlist } from "../../assets/playlist_icon.svg";
import { ReactComponent as play } from "../../assets/play_icon.svg";
//import { ReactComponent as bonus } from "../../assets/bonus_icon.svg";
import { Link } from "react-router-dom";

const NavBar = () => {
  let history = useHistory();
  return (
    <NavBarWrapper>
      <IconWrapper>
        <IconContainer
          to="/playlists"
          active={history.location.pathname === "/playlists"}
        >
          <Playlist active={history.location.pathname === "/playlists"} />
          playlist
        </IconContainer>
        <IconContainer to="/" active={history.location.pathname === "/"}>
          <Play active={history.location.pathname === "/"} />
          play
        </IconContainer>
        <IconContainer
          to="/profile"
          active={history.location.pathname === "/profile"}
        >
          <Player active={history.location.pathname === "/profile"} />
          player
        </IconContainer>
      </IconWrapper>
    </NavBarWrapper>
  );
};

const NavBarWrapper = styled.div`
  height: 12vh;
  width: 100%;
  max-width: 1280px;
  z-index: 2;
  position: fixed;
  bottom: 0;
  background: #2296f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0 0;
`;

const IconWrapper = styled.div`
  height: 10vh;
  width: 80%;
  max-width: 500px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
  padding: 0 25px;
`;

const IconContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  height: 50px;
  text-decoration: none;
  color: ${(p) => (p.active ? "#2296F3" : "#A0A0A0")};
`;

const Player = styled(player)`
  width: 50px;
  cursor: pointer;
  outline: none;
  path {
    fill: ${(p) => (p.active ? "#2296F3" : "#A0A0A0")};
  }
`;

const Playlist = styled(playlist)`
  width: 50px;
  padding-top: 5px;
  cursor: pointer;
  outline: none;
  rect {
    fill: ${(p) => (p.active ? "#2296F3" : "#A0A0A0")};
  }
`;

const Play = styled(play)`
  width: 50px;
  padding-left: 5px;
  cursor: pointer;
  outline: none;
  path {
    fill: ${(p) => (p.active ? "#2296F3" : "#A0A0A0")};
  }
`;

/*
const Bonus = styled(bonus)`
  width: 50px;
  cursor: pointer;
  path {
    fill: ${(p) => (p.active ? "#2296F3" : "#A0A0A0")};
  }
`;
*/

export default NavBar;
