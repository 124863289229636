import React, { useEffect, useState } from "react";
import Api from "../../services/api.service";
import Loading from "../../layout/loading-screen/loading";

import {
  Header,
  PlaylistsWrapper,
  PlaylistsContainer,
  PageSelect,
  NextPage,
  Page,
} from "./playlists.style";
import PlaylistCard from "../../components/playlist-card/playlist-card.component";
import NavBar from "../../layout/nav-bar/nav-bar";

const Playlists = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function loadPlaylist() {
      const response = await Api.playlist(page);
      setResults(response.data);
      setPageCount(response.data.last_page);
      window.scrollTo(0, 0);
      page === 1 && setLoading(false);
    }
    loadPlaylist();
  }, [page]);
  return loading ? (
    <Loading />
  ) : (
    <PlaylistsWrapper>
      <Header>Playlists</Header>
      <PlaylistsContainer>
        {results && results.data.length > 1
          ? results.data.map((res) => (
              <PlaylistCard
                key={res.id}
                id={res.id}
                src={res.cover && res.cover.url}
                title={res.title}
              />
            ))
          : "Nessuna playlist"}
      </PlaylistsContainer>
      <PageSelect>
        <NextPage onClick={() => setPage((p) => p - 1)} show={page > 1}>
          {"<<"}
        </NextPage>
        {results && results.data.length > 1 && <Page>{page}</Page>}
        <NextPage onClick={() => setPage((p) => p + 1)} show={page < pageCount}>
          {">>"}
        </NextPage>
      </PageSelect>
      <NavBar />
    </PlaylistsWrapper>
  );
};

export default Playlists;
