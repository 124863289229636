import styled from "styled-components";
import { Link } from "react-router-dom";

export const ResumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
`;

export const EndHeader = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 15vh;
  font-size: 54px;
  font-weight: bold;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  align-items: center;
  justify-content: center;
`;

export const ResumeContainer = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 80%;
  height: 60vh;
  font-size: 18px;
  flex-direction: column;
  border-radius: 30px;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
  box-sizing: border-box;
`;

export const EndFooter = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 12vh;
  flex-direction: column;
  border-radius: 30px 30px 0 0;
  align-items: center;
  justify-content: center;
`;

export const Points = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TotalPoints = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

export const Position = styled.div`
  font-size: 35px;
  font-weight: bold;
`;

export const ReplayButton = styled(Link)`
  background: #2296f3;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
`;

export const HomeButton = styled(Link)`
  background: #2296f3;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
`;

export const Button = styled.button`
  background: #2296f3;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
`;
