import styled from "styled-components";

export const PlaylistCardWrapper = styled.div`
  background: #fff;
  width: 170px;
  margin: 10px 0;
  color: #39a7ff;
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  text-transform: uppercase;
  min-height: 115px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  cursor: pointer;
`;

export const PlaylistCover = styled.div`
  height: 90px;
  width: 100%;
  background: url(${(p) => p.src});
  background-size: cover;
  margin-bottom: 5px;
`;

export const NoCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  height: 90px;
  font-size: 10px;
  width: 100%;
  background: none;
  background-size: cover;
  margin-bottom: 5px;
`;
