import React, { useState } from "react";
import {
  Wrapper,
  PlayButton,
  Artist,
  Title,
  SongWrapper,
  ArtistWrapper,
  IconWrapper,
  Guessed,
  NotGuessed,
} from "./guessed-song.style";

const GuessedSong = ({ song }) => {
  return (
    <Wrapper>
      <SongWrapper
        to={{
          pathname: "/store/" + song.id,
          data: { song },
        }}
      >
        <PlayButton />
        <ArtistWrapper>
          <Artist>{song.artist_name}</Artist>
          <Title>{song.title}</Title>
        </ArtistWrapper>
      </SongWrapper>

      <IconWrapper>
        {song.guessed ? <Guessed /> : <NotGuessed />}
        {song.guessed && song.is_artist_question ? <Guessed /> : <NotGuessed />}
      </IconWrapper>
    </Wrapper>
  );
};

export default GuessedSong;
