import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./app.css";
// services import
import Api from "./services/api.service";
import StorageService from "./services/storage.service";
import AppContext from "./services/context.service";
import { AuthRoute, PrivateRoute } from "./services/custom-routes.service";
// screens import
import Login from "./screens/login/login.screen";
import Home from "./screens/home/home.screen";
import Game from "./screens/game/game.screen";
import GameResume from "./screens/game-resume/game.resume.screen";
import Categories from "./screens/categories/categories.screen";
import Playlists from "./screens/playlists/playlists.screen";
import Profile from "./screens/profile/profile.screen";
import SongStore from "./screens/song-store/song-store.screen";
//import Bonus from "./screens/bonus/bonus.screen";

function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const login = async (deviceId, stayConnected) => {
    const {
      data: { token },
    } = await Api.login(deviceId);
    if (stayConnected) {
      StorageService.setToken(token);
    }
    Api.setTemporaryToken(token);
    setLoggedIn(true);
  };

  const loginSocial = async (provider, accessToken) => {
    const {
      data: { token },
    } = await Api.loginsocial(provider, accessToken);
    // always sets token, for social login
    StorageService.setToken(token);
    Api.setTemporaryToken(token);
    setLoggedIn(true);
  };

  useEffect(() => {
    const isLoggedIn = !!StorageService.getToken();
    setLoggedIn(isLoggedIn);
    setLoading(false);
  }, []);

  const logout = () => {
    StorageService.removeToken();
    setLoggedIn(false);
  };
  const getContext = () => ({
    login,
    loginSocial,
    logout,
  });

  if (loading) return null;
  return (
    <Router>
      <AppContext.Provider value={getContext()}>
        <Switch>
          <AuthRoute
            isAuthenticated={loggedIn}
            component={Login}
            path="/login"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={Home}
            exact
            path="/"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={Game}
            exact
            path="/playlist/:id"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={GameResume}
            exact
            path="/playlist/:id/resume/:gameId"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={Categories}
            exact
            path="/playlists/categories"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={Playlists}
            exact
            path="/playlists"
          />
          {/*  <PrivateRoute
            isAuthenticated={loggedIn}
            component={Bonus}
            exact
            path="/bonus"
          /> */}
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={Profile}
            exact
            path="/profile"
          />
          <PrivateRoute
            isAuthenticated={loggedIn}
            component={SongStore}
            exact
            path="/store/:songId"
          />
        </Switch>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
