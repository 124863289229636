import React from "react";
import { useHistory } from "react-router-dom";
import {
  PlaylistCardWrapper,
  PlaylistCover,
  NoCover,
} from "./playlist-card.style";

const PlaylistCard = (props) => {
  let history = useHistory();
  return (
    <PlaylistCardWrapper onClick={() => history.push("/playlist/" + props.id)}>
      {props.src ? (
        <PlaylistCover src={props.src} />
      ) : (
        <NoCover>No media</NoCover>
      )}
      {props.title}
    </PlaylistCardWrapper>
  );
};

export default PlaylistCard;
