import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import AppContext from "../../services/context.service";
import Button from "../../components/button/button.component";
import {
  Logo,
  FormWrapper,
  Body,
  Form,
  Row,
  Text,
  TextInput,
  Checkbox,
  ErrorText,
} from "./login.style";
import SocialButton from "../../components/social-button/social-button.component";

function LoginScreen() {
  const { login, loginSocial } = useContext(AppContext);
  const { register, handleSubmit, control, errors, setError } = useForm({
    defaultValues: {
      deviceId: "",
      stayConnected: 0,
      termsAndConditions: 0,
    },
  });

  async function onSubmit({ deviceId, stayConnected, termsAndConditions }) {
    if (!termsAndConditions) {
      return setError(
        "mainError",
        "authorization",
        "Accetta i termini e le condizioni prima di continuare"
      );
    }

    try {
      await login(deviceId, stayConnected);
    } catch (e) {
      const { response } = e;
      if (!response) {
        alert(JSON.stringify(e));
        return setError(
          "mainError",
          "unknown",
          "Impossibile eseguire il login, riprova più tardi"
        );
      } else {
        if (response.status !== 200) {
          return setError("mainError", "unknown", "Credenziali non valide");
        }
      }
    }
  }

  async function postSocialLogin(data) {
    try {
      await loginSocial(data._provider, data._token.accessToken);
    } catch (e) {
      const { response } = e;
      if (!response) {
        return setError(
          "mainError",
          "unknown",
          "Impossibile eseguire il login, riprova più tardi"
        );
      }
    }
  }

  // react-social-login: this function is called on successful login
  const handleSocialLogin = (data) => {
    // here we POST received data to backend, to revalidate and recreate user
    postSocialLogin(data);
  };

  // react-social-login: this function is called on login error
  const handleSocialLoginFailure = (err) => {
    setError("mainError", "unknown", "Impossibile eseguire il login");
  };

  return (
    <Body>
      <Logo />
      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ErrorText>{errors.mainError && errors.mainError.message}</ErrorText>

          <TextInput
            name="deviceId"
            autoComplete="on"
            error={errors.deviceId}
            label="Nome utente"
            ref={register({ required: true })}
          />

          <TextInput
            name="password"
            autoComplete="off"
            error={errors.password}
            label="Password"
            ref={register({ required: true })}
          />

          <Row>
            <Controller
              name="stayConnected"
              as={
                <Checkbox
                  renderLabel={() => (
                    <Text color="#2296F3">Resta connesso</Text>
                  )}
                />
              }
              control={control}
            />
            <Text>Recupera la password</Text>
          </Row>

          <Controller
            name="termsAndConditions"
            control={control}
            as={
              <Checkbox
                renderLabel={() => (
                  <Text>
                    <span style={{ color: "#2296F3" }}>
                      Ho letto ed accetto{" "}
                    </span>
                    Termini e Condizioni
                    <span style={{ color: "#2296F3" }}> e </span>
                    Privacy policy
                  </Text>
                )}
              />
            }
          />
          <Button type="submit" style={{ width: "100%" }}>
            ACCEDI
          </Button>
          <SocialButton
            style={{ width: "100%" }}
            provider="facebook"
            appId="1348283988695947"
            onLoginSuccess={handleSocialLogin}
            onLoginFailure={handleSocialLoginFailure}
          >
            ACCEDI CON FACEBOOK
          </SocialButton>
        </Form>
      </FormWrapper>
    </Body>
  );
}

export default LoginScreen;
