import styled from "styled-components";

const AppButton = styled.button`
  background: #2296f3;
  border-radius: 22px;
  border: none;
  color: white;
  margin: 5px 0;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  outline: none;
`;

export default AppButton;
