import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AuthRoute = ({ isAuthenticated: isAuth, component: Cmp, ...rest }) => (
  <Route
    {...rest}
    render={p => (!isAuth ? <Cmp {...p} /> : <Redirect to={{ pathname: '/' }} />)}
  />
);

export const PrivateRoute = ({ isAuthenticated: isAuth, component: Cmp, ...rest }) => (
  <Route
    {...rest}
    render={p => (isAuth ? <Cmp {...p} /> : <Redirect to={{ pathname: '/login' }} />)}
  />
);
