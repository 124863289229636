import axios from "axios";
import StorageService from "./storage.service";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "/api/v1"
    : "https://api.musify.it/api/v1";
axios.defaults.headers.common.Accept = "application/json";

class Api {
  static _temporaryToken = undefined;

  static request = async (
    method,
    url,
    params = {},
    headers = {},
    data = {},
    useToken
  ) => {
    if (useToken) {
      const token = this._temporaryToken || StorageService.getToken();
      headers.Authorization = `Bearer ${token}`;
    }
    return axios({ method, url, params, headers, data });
  };

  static get = async (url, opt = {}, useToken = false) =>
    this.request("get", url, opt.params, opt.headers, opt.data, useToken);
  static post = async (url, opt = {}, useToken = false) =>
    this.request("post", url, opt.params, opt.headers, opt.data, useToken);
  static delete = async (url, opt = {}, useToken = false) =>
    this.request("delete", url, opt.params, opt.headers, opt.data, useToken);
  static patch = async (url, opt = {}, useToken = false) =>
    this.request("patch", url, opt.params, opt.headers, opt.data, useToken);
  static put = async (url, opt = {}, useToken = false) =>
    this.request("put", url, opt.params, opt.headers, opt.data, useToken);

  // GET requests
  static playlist = async (page) =>
    this.get("/listing", { params: { page: page, perPage: 14 } }, true);
  static endless = async (id) =>
    this.get("/start/endless", { params: { id: id } }, true);
  static game = async (id) => this.get("/start", { params: { id: id } }, true);
  static user = async (user_id) =>
    this.get("/user", { params: { user_id: user_id } }, true);
  static gameResume = async (id) => this.get("/game/" + id, {}, true);
  static categories = async (page) =>
    this.get("playlists/categories", { params: { page: page } }, true);
  static gameResults = async (game_id) =>
    this.get(
      "game/" + game_id + "/status",
      { params: { game_id: game_id } },
      true
    );
  // POST requests

  // POST requests
  static login = async (deviceId) =>
    this.post("/login", { params: { deviceId } });
  static loginsocial = async (provider, token) =>
    this.post("/loginsocial", { data: { provider, token } });
  static updategame = async (answer, time, question, gameid) =>
    this.post(
      "/game/" + gameid + "/update",
      { params: { answer, time, question } },
      true
    );
  // DELETE requests
  // PATCH requests
  // PUT requests
  // UTILS
  static setTemporaryToken = (value) => (this._temporaryToken = value);
  static createFormDataFromObject = (obj) => {
    const formData = new FormData();
    Object.entries(obj).forEach(([key, value]) => formData.set(key, value));
    return formData;
  };
}

export default Api;
