import styled from "styled-components";
import { ReactComponent as ProPic } from "../../assets/profile_pic.svg";
import { ReactComponent as PlusBtn } from "../../assets/plus_button.svg";
import { ReactComponent as logo } from "../../assets/logo.svg";
import { ReactComponent as hexagon } from "../../assets/hex.svg";

export const UserBarWrapper = styled.div`
  width: 100%;
`;

export const LogoWrapper = styled.div`
  background: #2296f3;
  width: 100%;
  text-align: center;
  height: 40px;
`;

export const Logo = styled(logo)`
  width: 100px;
`;

export const Hexagon = styled(hexagon)`
  width: 100%;
`;

export const UserBar = styled.div`
  background: #2296f3;
  width: 100%;
  height: 260px;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  display: flex;
  @media screen and (min-width: 950px) {
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
    height: 200px;
    padding-top: 10px;
  }
`;

export const PlusButton = styled(PlusBtn)`
  position: relative;
  right: 25px;
`;

export const ProfilePic = styled(ProPic)`
  width: 140px;
  height: auto;
  margin-left: -47px;
  border: 2px solid white;
  padding: 1px 3px 4px;
  border-radius: 50%;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (min-width: 950px) {
    width: 50%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  z-index: 1;
  align-items: center;
  margin-right: -30px;
  margin-left: -10px;
`;

const BonusBar = styled.div`
  height: 18px;
  padding-left: 6px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  position: relative;
  right: 10px;
  z-index: -1;
`;

export const CoinsBar = styled(BonusBar)`
  background: #23bca1;
  width: 100px;
  width: 100px;
`;

export const EnergyBar = styled(BonusBar)`
  background: #fdc433;
  width: 40px;
`;

export const NotesBar = styled(EnergyBar)`
  background: #e83091;
`;

export const BonusRow = styled.div`
  display: flex;
  height: 87px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HexagonWrapper = styled.div`
  position: relative;
  text-align: center;
  height: 56px;
  left: 34%;
  width: 47px;
  top: 80px;
`;

export const BonusWrapper = styled.div`
  text-align: center;
  margin: -6px;
  width: 61.8px;
  padding-top: 20px;
`;

export const ProfileInfo = styled.div`
  width: 52%;
  margin-top: 14px;
  color: white;
`;

export const UserLevel = styled.span`
  position: relative;
  bottom: 70%;
  color: black;
  font-weight: 900;
  font-size: 18px;
`;

export const Name = styled.span`
  color: white;
  height: 18px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 25px;
`;

export const BonusLevel = styled.span`
  position: relative;
  bottom: 32px;
  color: white;
  font-weight: 700;
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const ResponsiveRow = styled(Row)`
  @media screen and (min-width: 950px) {
    width: 50%;
  }
`;
