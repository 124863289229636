import React, { useEffect, useState } from "react";
import {
  GameResultsWrapper,
  PlaylistCover,
  XpLevel,
  LevelWrapper,
  NavWrapper,
  PlaylistSongs,
  XpBar,
  PercentageCircle,
  PercentageWrapper,
  Percentage,
  LabelWrapper,
  BackButton,
  Label,
} from "./game-results.style";
import GuessedSong from "../../components/guessed-song/guessed-song.component";

const GameResults = ({ results, back, cover }) => {
  return (
    <GameResultsWrapper>
      <PlaylistCover background={cover ? cover.url : null} />
      <LevelWrapper>
        <PercentageCircle>
          <PercentageWrapper>
            <Percentage>
              {Math.trunc((results.results.guessed * 100) / 6)}%
            </Percentage>
          </PercentageWrapper>
        </PercentageCircle>
        <XpBar>
          <XpLevel level={results.results.guessed} />
        </XpBar>
      </LevelWrapper>
      <NavWrapper>
        <BackButton onClick={() => back()}>{"<"}</BackButton>
        <PlaylistSongs>
          {results.results.songs.length} canzoni nella partita
        </PlaylistSongs>
      </NavWrapper>
      <LabelWrapper>
        <Label>Canzone</Label>
        <Label>Artista</Label>
      </LabelWrapper>
      {results.results.songs.map((song) => (
        <GuessedSong key={song.id} song={song} />
      ))}
    </GameResultsWrapper>
  );
};

export default GameResults;
