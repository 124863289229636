import styled, { keyframes, css } from "styled-components";
import { ReactComponent as play } from "../../assets/play.svg";
import { Link } from "react-router-dom";

export const PlaylistCover = styled.div`
  background: #2296f3;
  position: relative;
  top: 0;
  height: 25vh;
  width: 100%;
  background: ${(p) => (p.background ? `url(${p.background})` : "#2296f3")};
  background-size: cover;
`;

export const GameScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const GameWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  bottom: 25px;
  position: relative;
`;

export const AnswerBar = styled.div`
  background: white;
  display: flex;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 90px;
  width: 344px;
  height: 63px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
`;

export const ScoreBar = styled(AnswerBar)`
  width: 182px;
  height: 45px;
  justify-content: space-between;
`;

export const Score = styled.span`
  margin: 0 10px;
  max-width: 30%;
  font-weight: bold;
`;

export const PartialScore = styled(Score)`
  font-size: 12px;
`;

const handleColor = (color) => {
  switch (color) {
    case "correct":
      return "#78C721";
    case "wrong":
      return "#FF4A4A";
    default:
      return "none";
  }
};

export const AnswerCircle = styled.div`
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  margin: 8px 0 8px 9px;
  font-size: 9px;
  font-weight: bold;
  color: ${({ color }) => handleColor(color)};
  border-radius: 50%;
`;

export const CircleWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  padding: 20px 0;
`;

export const Timer = styled.span`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2e313c;
  font-size: 30px;
  font-weight: 700;
`;

export const ReadyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RulesText = styled.div`
  color: #848484;
  margin: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
`;

export const ReadyText = styled.div`
  color: #2296f3;
  font-size: 28px;
  text-align: center;
  margin: 20px;
  font-weight: bold;
`;

export const Play = styled(play)``;

export const StartCountdown = styled.div`
  color: #2296f3;
  font-size: 90px;
  text-align: center;
  margin-bottom: 50px;
  font-weight: bold;
`;

const handleBorderType = (border) => {
  switch (border) {
    case "correct":
      return "2px solid green";
    case "wrong":
      return "2px solid red";
    default:
      return "none";
  }
};

const slideIn = keyframes`
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(150%);
  }
`;

export const QuestionButton = styled.button`
  background: #f8f8f8;
  color: #333333;
  margin: 5px;
  font-size: 18px;
  border-radius: 22px;
  font-weight: 600;
  outline: 0;
  width: 90%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: ${({ border }) => handleBorderType(border)};
  transform: ${(p) => (p.enter ? "translateX(-150%)" : "translateX(0%)")};
  :disabled {
    cursor: not-allowed;
  }
  animation: ${(props) =>
    props.enter
      ? css`
          ${slideIn} 1s ${(p) => p.delay}s forwards;
        `
      : css`
          ${slideOut} 1s ${(p) => p.delay}s forwards;
        `};
  cursor: pointer;
  outline: none;
`;

export const BackFooter = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 12vh;
  flex-direction: column;
  border-radius: 30px 30px 0 0;
  align-items: center;
  justify-content: center;
`;

export const PreGameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
`;

export const HomeButton = styled(Link)`
  background: #2296f3;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
`;
