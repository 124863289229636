import React from "react";
import styled from "styled-components";
import { ReactComponent as logo } from "../../assets/logo.svg";

const Loading = (props) => {
  return (
    <Wrapper>
      <Logo />
      <Text>Caricamento</Text>
      {props.value && (
        <ProgressBar id="songs" value={props.value} max="6"></ProgressBar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #2296f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled(logo)`
  width: 200px;
`;

const Text = styled.span`
  font-size: 20px;
  font-weight: 200;
  color: white;
  margin-top: 20px;
`;

const ProgressBar = styled.progress`
  ::-webkit-progress-bar {
    -webkit-appearance: none;
    appearance: none;
    width: 200px;
    height: 40px;
    background: #2296f3;
    margin-top: 10px;
  }
`;

export default Loading;
