import React from "react";
import {
  UserBarWrapper,
  Logo,
  UserBar,
  LogoWrapper,
  ItemRow,
  ItemWrapper,
  CoinsBar,
  EnergyBar,
  NotesBar,
  PlusButton,
  ResponsiveRow,
  Row,
  HexagonWrapper,
  Hexagon,
  UserLevel,
  ProfilePic,
  Column,
  BonusRow,
  BonusLevel,
  BonusWrapper,
  Name,
} from "./UserBarHome.style";
//Assets import
import { ReactComponent as Coins } from "../../assets/coins.svg";
import { ReactComponent as Energy } from "../../assets/energy.svg";
import { ReactComponent as Notes } from "../../assets/notes.svg";
import { ReactComponent as FiftyBonus } from "../../assets/fifty_bonus.svg";
import { ReactComponent as EnergyBonus } from "../../assets/energy_bonus.svg";
import { ReactComponent as DoubleBonus } from "../../assets/double_bonus.svg";
import { ReactComponent as CorrectBonus } from "../../assets/correct_answer_bonus.svg";

const UserBarHome = ({ user }) => {
  return (
    <UserBarWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <UserBar>
        <ItemRow>
          <ItemWrapper>
            <Coins />
            <CoinsBar> {user.wallet.coins} </CoinsBar>
            <PlusButton />
          </ItemWrapper>
          <ItemWrapper>
            <Energy />
            <EnergyBar> {user.wallet.energy_points} </EnergyBar>
            <PlusButton />
          </ItemWrapper>
          <ItemWrapper>
            <Notes />
            <NotesBar> {user.wallet.musical_notes} </NotesBar>
            <PlusButton />
          </ItemWrapper>
        </ItemRow>
        <ResponsiveRow>
          <Row>
            <HexagonWrapper>
              <Hexagon />
              <UserLevel>{user.level}</UserLevel>
            </HexagonWrapper>
            <ProfilePic />
          </Row>
          <Column>
            <BonusRow>
              <BonusWrapper>
                <DoubleBonus />
                <BonusLevel> {user.wallet.fifty_fifty} </BonusLevel>
              </BonusWrapper>
              <BonusWrapper>
                <CorrectBonus />
                <BonusLevel> {user.wallet.correct_answer} </BonusLevel>
              </BonusWrapper>
              <BonusWrapper>
                <FiftyBonus />
                <BonusLevel> {user.wallet.double_possibility} </BonusLevel>
              </BonusWrapper>
              <BonusWrapper>
                <EnergyBonus />
                <BonusLevel> {user.wallet.time_freeze} </BonusLevel>
              </BonusWrapper>
            </BonusRow>
            <Name> {user.name} </Name>
          </Column>
        </ResponsiveRow>
      </UserBar>
    </UserBarWrapper>
  );
};

export default UserBarHome;
