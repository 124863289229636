import styled from "styled-components";
import { Link } from "react-router-dom";

export const PlaylistsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-bottom: 12vh;
`;

export const PlaylistsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 15vh;
  font-size: 32px;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  align-items: center;
  justify-content: center;
`;

export const PageSelect = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2296f3;
  margin: 10px 0;
`;

export const NextPage = styled.div`
  font-size: 16px;
  visibility: ${(p) => (p.show ? "visible" : "hidden")};
  color: #2296f3;
  cursor: pointer;
`;

export const Page = styled.div`
  font-size: 16px;
  color: #2296f3;
  margin: 0 10px;
`;
