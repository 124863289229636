import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Header,
  Store,
  Footer,
  HomeButton,
  StoreButton,
  AppleMusicButtonLink,
  DeezerButtonLink,
  YoutubeButtonLink,
  SpotifyButtonLink,
  PlayButton,
  InfoWrapper,
  SongTitle,
  ArtistName,
} from "./song-store.style";
import { useHistory } from "react-router-dom";
import AudioService from "../../services/audio.service";

const SongStore = (props) => {
  let history = useHistory();
  const [songPlaying, setSongPlaying] = useState(false);
  const song = props.location.data.song || null;
  console.log(song);
  const deezerSource = song.stores.find((song) => song.source === "deezer");
  const appleMusicSource = song.stores.find(
    (song) => song.source === "applemusic"
  );
  const youtubeSource = song.stores.find((song) => song.source === "youtube");
  const spotifySource = song.stores.find((song) => song.source === "spotify");

  const handlePlay = () => {
    if (songPlaying) {
      AudioService.pauseSong();
      setSongPlaying(false);
    } else {
      setSongPlaying(true);
      AudioService.playSong(song.deezer_url);
    }
  };
  useEffect(() => {
    return () => {
      AudioService.stopSong();
      AudioService.stopAll();
    };
  }, [history.location.pathname]);
  return (
    <Wrapper>
      <Header>Dettagli canzone</Header>
      <InfoWrapper>
        <PlayButton onClick={() => handlePlay()} />
        <SongTitle>{song.title}</SongTitle>
        <ArtistName>{song.artist_name}</ArtistName>
      </InfoWrapper>
      <Store>
        {appleMusicSource && (
          <StoreButton>
            <AppleMusicButtonLink link={appleMusicSource.link} />
          </StoreButton>
        )}
        {spotifySource && (
          <StoreButton>
            <SpotifyButtonLink link={spotifySource.link} />
          </StoreButton>
        )}
        {deezerSource && (
          <StoreButton>
            <DeezerButtonLink link={deezerSource.link} />
          </StoreButton>
        )}
        {youtubeSource && (
          <StoreButton>
            <YoutubeButtonLink link={youtubeSource.link} />
          </StoreButton>
        )}
      </Store>
      <Footer>
        <HomeButton onClick={() => history.push("/")}>
          Torna alla home
        </HomeButton>
      </Footer>
    </Wrapper>
  );
};

export default SongStore;
