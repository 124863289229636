import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import UserBarHome from "../../components/user-bar-home/UserBarHome.component";
import Button from "../../components/button/button.component";
import { Link } from "react-router-dom";
import { ReactComponent as EndlessButton } from "../../assets/rotation.svg";
import { ReactComponent as EndlessLine } from "../../assets/endless_lines.svg";
import Loading from "../../layout/loading-screen/loading";
import PlaylistCard from "../../components/playlist-card/playlist-card.component";
import NavBar from "../../layout/nav-bar/nav-bar";
import {
  HomeWrapper,
  PlaylistWrapper,
  Container,
  Rotate,
  EndlessCircle,
} from "./home.style";

const HomeScreen = () => {
  const [PlaylistData, setPlaylistData] = useState([]);
  const [UserData, setUserData] = useState();
  const [PlaylistDataLoading, setPlaylistDataLoading] = useState(true);
  const [UserDataLoading, setUserDataLoading] = useState(true);

  useEffect(() => {
    async function getPlaylist() {
      const response = await Api.playlist(1);
      setPlaylistData(response.data.data);
      setPlaylistDataLoading(false);
    }
    getPlaylist();
  }, []);

  useEffect(() => {
    async function getUser() {
      const response = await Api.user();
      setUserData(response.data);
      setUserDataLoading(false);
    }
    getUser();
  }, []);

  return !PlaylistDataLoading && !UserDataLoading ? (
    <HomeWrapper>
      <UserBarHome user={UserData} />
      <Container
        style={{
          margin: "20px 0  5px 0",
          justifyContent: "space-evenly",
          position: "relative",
        }}
      >
        <EndlessLine style={{ transform: "rotate(180deg)" }} />
        <EndlessCircle>
          <Rotate>
            <EndlessButton />
          </Rotate>
        </EndlessCircle>
        <EndlessLine />
        <Container
          style={{
            color: "#39A7FF",
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "center",
            margin: 5,
          }}
        >
          ENDLESS
          <br />
          GAME
        </Container>
      </Container>
      <Container
        style={{
          background: "#2296F3",
          borderRadius: "30px 30px 0 0",
          padding: "22.5px 0 22.5px 0",
          color: "#FFF",
          display: "flex",
          flexDirection: "column",
          fontSize: 21,
          width: "100%",
          fontWeight: "700",
          textAlign: "center",
          paddingBottom: "12vh",
        }}
      >
        PLAYLIST
        <br />
        PIÙ GIOCATE
        <PlaylistWrapper>
          {PlaylistData.map((res) => (
            <PlaylistCard
              key={res.id}
              id={res.id}
              src={res.cover && res.cover.url}
              title={res.title}
            />
          ))}
        </PlaylistWrapper>
        <Link to="/playlists/categories">
          <Button style={{ border: "2px solid white" }}>
            SFOGLIA CATEGORIE
          </Button>
        </Link>
        <Link to="/playlists">
          <Button style={{ border: "2px solid white" }}>
            VEDI TUTTE LE PLAYLIST
          </Button>
        </Link>
      </Container>
      <NavBar />
    </HomeWrapper>
  ) : (
    <Loading />
  );
};
export default HomeScreen;
