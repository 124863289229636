import Wad from "web-audio-daw";
import beep from "../assets/sounds/beep.mp3";
import buttonsound from "../assets/sounds/but_press.mp3";
import correct from "../assets/sounds/correct.mp3";
import error from "../assets/sounds/error.mp3";
import { SongTitle } from "../screens/song-store/song-store.style";

class AudioService {
  static audio_source = [];
  static loaded = [];
  static playingSong = null;
  static audio_loaded = 0;
  static beepSound = new Wad({ source: beep });
  static correctSound = new Wad({ source: correct });
  static errorSound = new Wad({ source: error });
  static load = (songs, callback = () => {}, updateLoadedSongs = () => {}) => {
    songs.forEach((song, index) => {
      const songElement = new Wad({ source: song.preview });
      this.audio_source[index] = songElement;
      const controller = setInterval(() => {
        if (this.audio_loaded < 6 && songElement.playable === 1) {
          this.audio_loaded++;
          clearInterval(controller);
          updateLoadedSongs();
        }
        if (this.audio_loaded === 6) {
          callback();
          this.audio_loaded = 0;
        }
      }, 250);
    });
  };
  static playCorrect = () => {
    this.correctSound.play();
  };
  static playError = () => {
    this.errorSound.play();
  };
  static playBeep = () => {
    this.beepSound.play();
  };
  static playSong = (song) => {
    if (this.playingSong) {
      this.playingSong.unpause();
    } else {
      const songElement = new Wad({ source: song });
      this.playingSong = songElement;
      songElement.play();
    }
  };
  static pauseSong = () => {
    this.playingSong.pause();
  };
  static stopSong = () => {
    if (this.playingSong) {
      this.playingSong.stop();
    }
    this.playingSong = null;
  };
  static play = (index) => {
    if (!this.audio_source[index]) return;
    this.audio_source[index].play();
  };
  static stop = (index) => {
    if (!this.audio_source[index]) return;
    this.audio_source[index].stop();
  };
  static stopAll = () => {
    this.audio_source.forEach((element) => {
      element.stop();
    });
    this.audio_source = [];
  };
}

export default AudioService;
