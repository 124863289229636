import styled from "styled-components";

export const GameResultsWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlaylistCover = styled.div`
  background: #2296f3;
  position: relative;
  top: 0;
  height: 25vh;
  width: 100%;
  background: ${(p) => (p.background ? `url(${p.background})` : "#2296f3")};
  background-size: cover;
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateY(-27px);
`;

export const NavWrapper = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const BackButton = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: #2296f3;
`;

export const UserLevel = styled.span`
  position: relative;
  bottom: 70%;
  color: black;
  font-weight: 900;
  font-size: 18px;
`;

export const HexagonWrapper = styled.div`
  text-align: center;
  height: 56px;
  width: 47px;
  z-index: 1;
`;

export const XpBar = styled.div`
  width: 250px;
  height: 18px;
  border-radius: 0 30px 30px 0;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  transform: translateX(-5px);
`;

export const XpLevel = styled.div`
  width: ${(p) => (p.level * 200) / 6}px;
  max-width: 248px;
  height: 14px;
  border-radius: 0 30px 30px 0;
  margin-left: 4px;
  background: #2296f3;
  display: flex;
`;

export const Xp = styled.div`
  font-size: 14px;
  position: absolute;

  transform: translate(195px, -17px);
`;

export const PercentageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;

  border: 2px solid #2296f3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Percentage = styled.div`
  font-size: 14px;
  font-weight: 800;
  color: #2296f3;
  line-height: 1;
`;

export const PlaylistSongs = styled.div`
  font-size: 18px;
  font-weight: 800;
  color: #2296f3;
  line-height: 1;
`;

export const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const Label = styled.div`
  font-size: 10px;
  font-weight: 400;
  margin: 0 8px;
`;

export const PercentageCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 1;
  background: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;
