import styled from "styled-components";
import { Link } from "react-router-dom";

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 15vh;
  font-size: 32px;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 12vh;
  flex-direction: column;
  border-radius: 30px 30px 0 0;
  align-items: center;
  justify-content: center;
`;

export const HomeButton = styled(Link)`
  background: #2296f3;
  border-radius: 30px;
  border: 2px solid white;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
`;
