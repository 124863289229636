import React, { useEffect, useState } from "react";
import Api from "../../services/api.service";
import {
  Header,
  CategoriesWrapper,
  Footer,
  HomeButton,
} from "./categories.style";

const Categories = (props) => {
  const [page, setPage] = useState(1);
  const [results, setResults] = useState();
  useEffect(() => {
    async function loadCategories() {
      const response = await Api.categories(page);
      setResults(response.data);
    }
    loadCategories();
  }, []);
  return (
    <CategoriesWrapper>
      <Header>Categorie</Header>
      {results && results.length > 1 ? "Ci sono dati" : "Nessuna categoria :("}

      <Footer>
        <HomeButton to="/">torna alla home</HomeButton>
      </Footer>
    </CategoriesWrapper>
  );
};

export default Categories;
