import styled from "styled-components";
import React from "react";
import { ReactComponent as play } from "../../assets/play_song_icon.svg";
import { ReactComponent as guessed } from "../../assets/guessed_icon.svg";
import { ReactComponent as not_guessed } from "../../assets/not_guessed_icon.svg";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  box-sizing: border-box;
  padding: 0 10px;
`;

export const Play = styled(play)``;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: #f6f6f6;
  border-radius: 50%;
`;

export const PlayButton = () => {
  return (
    <Circle>
      <Play />
    </Circle>
  );
};

export const SongWrapper = styled(Link)`
  display: flex;
  align-items: center;
  max-width: 75%;
  text-decoration: none;
`;

export const ArtistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5px;
  max-width: 180px;
`;
export const Artist = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #2296f3;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #2296f3;
`;

export const IconWrapper = styled.div`
  width: 80px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Guessed = styled(guessed)`
  .a {
    fill: #2296f3;
  }
  .b {
    fill: white;
    opacity: 1;
  }
`;
export const NotGuessed = styled(not_guessed)`
  .a {
    fill: #fafafa;
  }
  .b {
    fill: #4a90e2;
    opacity: 0.5;
  }
`;
