import React from 'react'
import SocialLogin from 'react-social-login'
import Button from '../../components/button/button.component'

/*const SocialButton = (props) => (
  <button onClick={props.triggerLogin} {...props}/>
);*/

class SocialButton extends React.Component {
  render() {
      const { triggerLogin, ...props } = this.props; 
      return (
          <Button onClick={triggerLogin} {...props}/>
      );
  }
}


export default SocialLogin(SocialButton);