import styled, { keyframes } from "styled-components";

export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
`;

export const PlaylistWrapper = styled.div`
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
  transform: rotate(360deg);
  }
`;
export const Rotate = styled.div`
  display: flex;
  width: 53px;
  height: 62px;
  animation: ${rotate} 3s linear infinite;
`;

export const EndlessCircle = styled.div`
  border: 5px solid #2296f3;
  padding: 10px 12px 10px 12px;
  border-radius: 50%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;
