import styled from "styled-components";
import { ReactComponent as PlusBtn } from "../../assets/plus_button.svg";
import { ReactComponent as hexagon } from "../../assets/hex.svg";
import { ReactComponent as ProPic } from "../../assets/profile_pic.svg";

export const ProfilePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: white;
`;

export const UserBar = styled.div`
  background: #2296f3;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 30px 30px;
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  @media screen and (min-width: 500px) {
    width: 70%;
  }
`;

export const UserName = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

export const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemWrapper = styled.div`
  display: flex;
  z-index: 1;
  align-items: center;
  margin-right: -30px;
  margin-left: -10px;
`;

export const PlusButton = styled(PlusBtn)`
  position: relative;
  right: 25px;
`;

const BonusBar = styled.div`
  height: 18px;
  padding-left: 6px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  position: relative;
  right: 10px;
  z-index: -1;
`;

export const CoinsBar = styled(BonusBar)`
  background: #23bca1;
  width: 100px;
  width: 100px;
`;

export const EnergyBar = styled(BonusBar)`
  background: #fdc433;
  width: 40px;
`;

export const NotesBar = styled(EnergyBar)`
  background: #e83091;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${(p) => (p.noMargin ? 0 : "10px 0")};
  padding: ${(p) => (p.padding ? "0 10px" : 0)};
  box-sizing: border-box;
`;

export const CountsRow = styled(Row)`
  justify-content: space-evenly;
`;

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserLevel = styled.span`
  position: relative;
  bottom: 70%;
  color: black;
  font-weight: 900;
  font-size: 18px;
`;

export const HexagonWrapper = styled.div`
  text-align: center;
  height: 56px;
  width: 47px;
  z-index: 1;
`;

export const Hexagon = styled(hexagon)`
  width: 100%;
`;

export const ProfilePic = styled(ProPic)`
  width: 60px;
  height: 60px;
`;

export const XpBar = styled.div`
  width: 200px;
  height: 18px;
  border-radius: 30px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(-10%);
`;

export const XpLevel = styled.div`
  width: ${(p) => (p.level * 200) / 100}px;
  max-width: 198px;
  height: 14px;
  border-radius: 30px;
  background: #2296f3;
  display: flex;
`;

export const Xp = styled.div`
  font-size: 14px;
  position: absolute;
  transform: translate(195px, -17px);
`;

export const XpPercentage = styled.div`
  font-size: 14px;
  position: absolute;
  transform: translate(50px, 20px);
`;

export const CountText = styled.div`
  font-size: 12px;
  font-weight: lighter;
`;

export const GamesCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: bolder;
`;

export const PlaylistsCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: bolder;
`;

export const NavigationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
`;

export const NavLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40%;
  font-weight: bolder;
  padding-bottom: 6px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${(p) => (p.active ? "#2296f3" : "#9B9B9B")};
  border-bottom: 5px solid ${(p) => (p.active ? "#2296f3" : "#9B9B9B")};
  cursor: pointer;
`;

export const Logout = styled.button`
  background: #2296f3;
  border-radius: 22px;
  border: none;
  color: white;
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
`;
