import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import {
  EndHeader,
  ResumeWrapper,
  ResumeContainer,
  TotalPoints,
  Points,
  Position,
  EndFooter,
  ReplayButton,
  HomeButton,
  Button,
} from "./game.resume.style";
import Confetti from "react-confetti";
import GameResults from "../game-results/game-results.screen";

const GameResume = (props) => {
  const [gameResults, setGameResults] = useState();
  const [showResults, setShowResults] = useState(false);
  useEffect(() => {
    async function loadResults(answer) {
      const response = await Api.gameResults(props.match.params.gameId);
      setGameResults(response.data);
    }
    loadResults();
  }, []);

  if (showResults) {
    return (
      <GameResults
        results={gameResults}
        cover={props.location.data}
        back={() => setShowResults(false)}
      />
    );
  } else
    return (
      <ResumeWrapper>
        <Confetti
          width={document.body.width}
          height={document.body.height}
          numberOfPieces={500}
          recycle={false}
        />
        <EndHeader>Fine!</EndHeader>
        {gameResults && (
          <ResumeContainer>
            <Points>
              Punti totali
              <TotalPoints>{gameResults.total_score}</TotalPoints>
            </Points>
            <Points>
              La tua posizione
              <Position>{gameResults.position + 1}°</Position>
            </Points>
            <Button onClick={() => setShowResults(true)}>Vedi risultati</Button>
            <ReplayButton to={"/playlist/" + props.match.params.id}>
              Rigioca
            </ReplayButton>
          </ResumeContainer>
        )}
        <EndFooter>
          <HomeButton to="/">torna alla home</HomeButton>
        </EndFooter>
      </ResumeWrapper>
    );
};

export default GameResume;
