import styled from "styled-components";
import React from "react";
import Button from "../../components/button/button.component";
import { ReactComponent as apple_music } from "../../assets/apple_music_store.svg";
import { ReactComponent as deezer } from "../../assets/deezer_store.svg";
import { ReactComponent as spotify } from "../../assets/spotify_store.svg";
import { ReactComponent as youtube } from "../../assets/youtube_store.svg";
import { ReactComponent as play } from "../../assets/play.svg";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 15vh;
  font-size: 21px;
  font-weight: bold;
  flex-direction: column;
  border-radius: 0 0 30px 30px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const Store = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  background: #2296f3;
  color: white;
  width: 100%;
  height: 12vh;
  flex-direction: column;
  border-radius: 30px 30px 0 0;
  align-items: center;
  justify-content: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SongTitle = styled.div`
  margin-top: 45px;
  font-size: 27px;
  font-weight: black;
  text-align: center;
`;

export const ArtistName = styled.div`
  font-size: 18px;
  font-weight: normal;
`;

export const HomeButton = styled(Button)`
  border: 2px solid white;
  text-transform: uppercase;
`;

export const StoreButton = styled.div`
  width: 143px;
  height: 52px;
  background: #2296f3;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const AppleMusicIcon = styled(apple_music)``;

export const AppleMusicButtonLink = ({ link }) => {
  console.log(link);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <AppleMusicIcon />
    </a>
  );
};
export const DeezerIcon = styled(deezer)``;

export const DeezerButtonLink = ({ link }) => {
  console.log(link);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <DeezerIcon />
    </a>
  );
};

export const YoutubeIcon = styled(youtube)``;

export const YoutubeButtonLink = ({ link }) => {
  console.log(link);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <YoutubeIcon />
    </a>
  );
};

export const SpotifyIcon = styled(spotify)``;

export const SpotifyButtonLink = ({ link }) => {
  console.log(link);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <SpotifyIcon />
    </a>
  );
};

export const PlayButton = styled(play)`
  transform: translateY(30px);
  outline: none;
`;
