import React from "react";
import styled from "styled-components";

const CircleTimer = (props) => (
  <CircleTimerWrapper>
    <Circle1 cx="50" cy="50" r="40" />
    <Circle paused={props.paused} cx="50" cy="50" r="40" />
  </CircleTimerWrapper>
);

const Circle1 = styled.circle`
  fill: transparent;
  stroke-width: 10px;
  stroke: #f8f8f8;
`;

const Circle = styled.circle`
  fill: transparent;
  stroke-width: 10px;
  stroke-dashoffset: 500px;
  stroke-dasharray: 0;
  animation: 10s circletimer linear;
  stroke: #2296f3;
  animation-play-state: ${(p) => (p.paused ? "paused" : "running")};
  @keyframes circletimer {
    0% {
      stroke-dashoffset: 0;
      stroke-dasharray: 253;
    }
    100% {
      stroke-dashoffset: 250;
      stroke-dasharray: 250;
    }
  }
`;

const CircleTimerWrapper = styled.svg`
  height: 100px;
  width: 100px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(-90deg);
`;

export default CircleTimer;
