import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../services/api.service";
import {
  AnswerCircle,
  GameScreenWrapper,
  AnswerBar,
  ScoreBar,
  QuestionButton,
  PlaylistCover,
  Score,
  PartialScore,
  GameWrapper,
  Timer,
  CircleWrapper,
  HomeButton,
  ReadyWrapper,
  ReadyText,
  RulesText,
  PreGameWrapper,
  BackFooter,
  StartCountdown,
} from "./game.style";
import useCounter from "../../components/timer/timer.component";
import { Redirect } from "react-router-dom";
import Loading from "../../layout/loading-screen/loading";
import CircleTimer from "../../components/circle-timer/circle_timer.component";
import AudioService from "../../services/audio.service";

const GameScreen = (props) => {
  const [gameData, setGameData] = useState();
  const [loadedSongs, setLoadedSongs] = useState(false);
  const [ready, setReady] = useState(false);
  const [question, setQuestion] = useState(0);
  const { count, start, stop, reset } = useCounter(100, 100);
  const [answerData, setAnswerData] = useState();
  const [gameEnded, setGameEnded] = useState(false);
  const [timerAnimation, setTimerAnimation] = useState(true);
  const [counter, setCounter] = useState(5);
  const [startCountdown, setStartCountdown] = useState(false);
  const [gameID, setGameId] = useState();
  const [enterAnimation, setEnterAnimation] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [numLoadedSongs, setNumLoadedSongs] = useState(0.1);
  const countdownTimeout = useRef();
  let history = useHistory();
  async function updategame(answer) {
    setDisabledButton(true);
    stop();
    setTimerAnimation(true);
    AudioService.stop(question);
    let nextQuestion = question + 1;
    const response = await Api.updategame(
      answer,
      (100 - count) / 10,
      question,
      gameData.id
    );
    response.data.partial_score[question].correct_answer ||
    response.data.partial_score[question].correct_answer === 0
      ? AudioService.playError()
      : AudioService.playCorrect();
    setAnswerData(response.data);
    setTimeout(() => {
      setEnterAnimation(false);
      reset();
      setTimeout(() => {
        if (question < 5) {
          setQuestion(nextQuestion);
        }
      }, 1000);
    }, 500);
    setTimeout(() => {
      if (question < 5) {
        setEnterAnimation(true);
        setTimeout(() => {
          start();
          AudioService.play(nextQuestion);
          setTimerAnimation(false);
          setDisabledButton(false);
        }, 2000);
      } else {
        setGameEnded(true);
      }
    }, 2000);
  }

  const UpdateProgressBar = () => {
    setNumLoadedSongs(AudioService.audio_loaded);
  };

  const Loaded = () => {
    setLoadedSongs(true);
    setStartCountdown(true);
  };

  useEffect(() => {
    async function game() {
      const response = await Api.game(props.match.params.id);
      setGameData(response.data);
      setGameId(response.data.id);
      AudioService.load(
        response.data.questions,
        () => Loaded(),
        () => UpdateProgressBar()
      );
    }
    game();
  }, []);

  useEffect(() => {
    return () => {
      AudioService.stopAll();
    };
  }, [history.location.pathname]);

  useEffect(() => {
    if (count === 0) {
      updategame(-1);
    }
  }, [count]);

  useEffect(() => {
    if (startCountdown) {
      if (counter > 0) {
        countdownTimeout.current = setTimeout(() => {
          setCounter(counter - 1);
          counter > 1 && AudioService.playBeep();
        }, 1000);
      } else {
        setEnterAnimation(true);
        setReady(true);
        setTimeout(() => {
          setTimerAnimation(false);
          AudioService.play(question);
          start();
          setDisabledButton(false);
        }, 2000);
      }
      return () => clearTimeout(countdownTimeout.current);
    }
  }, [counter, startCountdown]);

  return (
    <GameScreenWrapper>
      {gameEnded && (
        <Redirect
          to={{
            pathname:
              "/playlist/" + props.match.params.id + "/resume/" + gameID,
            data: gameData.playlist.cover,
          }}
        />
      )}
      {!loadedSongs && <Loading value={numLoadedSongs} />}
      {loadedSongs && !ready && (
        <PreGameWrapper>
          <PlaylistCover
            background={
              gameData.playlist.cover ? gameData.playlist.cover.url : null
            }
          />
          <ReadyWrapper>
            <StartCountdown>{counter}</StartCountdown>
            <ReadyText>Alzato il volume?</ReadyText>
            <RulesText>
              Ascolta le canzoni e rispondi nel più breve tempo possibile
            </RulesText>
          </ReadyWrapper>
          <BackFooter>
            <HomeButton to="/">Annulla partita</HomeButton>
          </BackFooter>
        </PreGameWrapper>
      )}
      {ready && !gameEnded && (
        <>
          <PlaylistCover
            background={
              gameData.playlist.cover ? gameData.playlist.cover.url : null
            }
          />
          <GameWrapper>
            <ScoreBar>
              <Score>Punti</Score>
              <PartialScore key={question}>
                {answerData &&
                answerData.partial_score[question] &&
                ready &&
                count > 0
                  ? answerData.partial_score[question].score
                  : count * 25}
              </PartialScore>
              <Score> {answerData ? answerData.total_score : 0} </Score>
            </ScoreBar>
            <AnswerBar>
              {answerData &&
                answerData.partial_score.map((partialscore, i) => (
                  <AnswerCircle
                    color={partialscore.score === 0 ? "wrong" : "correct"}
                    key={i}
                  >
                    {partialscore.score > 0 ? partialscore.time + "s" : "X"}
                  </AnswerCircle>
                ))}
            </AnswerBar>
            <CircleWrapper key={question}>
              <CircleTimer paused={timerAnimation} />
              <Timer> {Math.round(count / 10)} </Timer>
            </CircleWrapper>
            {gameData.questions[question].options.map((option, i) => (
              <QuestionButton
                border={
                  answerData && answerData.partial_score[question]
                    ? answerData.partial_score[question].score === 0
                      ? answerData.partial_score[question].correct_answer === i
                        ? "correct"
                        : answerData.partial_score[question].answer === i &&
                          "wrong"
                      : answerData.partial_score[question].answer === i &&
                        "correct"
                    : "none"
                }
                key={i}
                delay={i * 0.3}
                enter={enterAnimation}
                disabled={disabledButton}
                onClick={() => updategame(i)}
              >
                {option}
              </QuestionButton>
            ))}
          </GameWrapper>
        </>
      )}
    </GameScreenWrapper>
  );
};

export default GameScreen;
